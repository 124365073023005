import { ChakraProvider, Heading, Link, Text, VStack } from '@chakra-ui/react';
import { withEmotionCache } from '@emotion/react';
import type { LinksFunction, MetaFunction } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useCatch } from '@remix-run/react';

import { ClientStyleContext, ServerStyleContext } from '~/lib/emotion/context';
import { theme } from '~/lib/theme';
import stylesheetUrl from './styles/styles.css';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import type { ReactNode } from 'react';
import { useContext, useEffect } from 'react';
import reactCalendarStyles from '../node_modules/react-calendar/dist/Calendar.css';
import sassyStyles from '../node_modules/sassy-datepicker/dist/styles.css';
import { ErrorPage } from './components/ErrorPage';

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

export const meta: MetaFunction = () => {
  return {
    charset: 'utf-8',
    title: 'CRISP',
    viewport: 'width=device-width,initial-scale=1'
  };
};

export let links: LinksFunction = () => {
  return [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap'
  },
  {
    rel: 'stylesheet',
    href: stylesheetUrl
  },
  {
    rel: 'icon',
    href: '/favicon.svg',
    type: 'image/svg+xml'
  },
  { rel: 'stylesheet', href: sassyStyles },
  { rel: 'stylesheet', href: reactCalendarStyles }];

};

interface DocumentProps {
  children: ReactNode;
}

const Document = withEmotionCache(({ children }: DocumentProps, emotionCache) => {
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      ;(emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <html lang="en">
            <head>
                <Meta />
                <Links />
                {serverStyleData?.map(({ key, ids, css }) =>
        <style
          key={key}
          data-emotion={`${key} ${ids.join(' ')}`}
          dangerouslySetInnerHTML={{ __html: css }} />

        )}
            </head>
            <body>
                <ChakraProvider theme={theme}>{children}</ChakraProvider>
                <ScrollRestoration />
                <Scripts />

                {process.env.NODE_ENV === 'development' ? <LiveReload /> : null}
            </body>
        </html>);

});

export default function App() {
  return (
    <Document>
            <ChakraProvider theme={theme}>
                <Outlet />
            </ChakraProvider>
        </Document>);

}

export function ErrorBoundary({ error }: {error: Error;}) {
  console.error('Boundary:', error);
  return (
    <Document>
            <ErrorPage
        heading="Something went wrong"
        description={
        <>
                        <Text color={'gray.500'}>
                            Oops, CRISP encountered an unexpected error and our team has been alerted. Please{' '}
                            <Link href="mailto:helpdesk@nwcomputing.com.au" isExternal>
                                email us
                            </Link>{' '}
                            if this error persists.
                        </Text>
                        {process.env.NODE_ENV === 'development' &&
          <Text size="sm" as="pre">
                                {error.stack}
                            </Text>}

                    </>} />


        </Document>);

}

export function CatchBoundary() {
  let caught = useCatch();
  let message;
  switch (caught.status) {
    case 401:
      message = <Text>Oops! Looks like you tried to visit a page that you do not have access to.</Text>;
      break;
    case 404:
      message = <Text>Oops! Looks like you tried to visit a page that does not exist.</Text>;
      break;

    default:
      throw new Error(caught.data || caught.statusText);
  }

  return (
    <Document>
            <VStack h="100vh" justify="center" p={20}>
                <Heading>
                    {caught.status}: {caught.statusText}
                </Heading>
                {message}
            </VStack>
        </Document>);

}